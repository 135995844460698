import { PICKUP_POINT_AMENITIES_LABELS } from "~/constants/pickupPoint";
import {
  Accessibility,
  Barcode,
  Bedtime,
  Car,
  EventAvailable,
} from "~/components/Icon";

const PickupAmenities = ({
  disabledAccess,
  parkingAvailable,
  openLate,
  openSaturday,
  openSunday,
  dropOffDpd,
}) => {
  const items = [
    {
      icon: Accessibility,
      showIcon: disabledAccess,
      description: PICKUP_POINT_AMENITIES_LABELS.WHEELCHAIR_ACCESS,
    },
    {
      icon: Barcode,
      showIcon: dropOffDpd,
      description: PICKUP_POINT_AMENITIES_LABELS.LABEL_PRINTING,
    },
    {
      icon: Bedtime,
      showIcon: openLate,
      description: PICKUP_POINT_AMENITIES_LABELS.OPEN_LATE,
    },
    {
      icon: Car,
      showIcon: parkingAvailable,
      description: PICKUP_POINT_AMENITIES_LABELS.CAR_PARKING,
    },
    {
      icon: EventAvailable,
      showIcon: openSaturday,
      description: PICKUP_POINT_AMENITIES_LABELS.OPEN_SATURDAYS,
    },
    {
      icon: EventAvailable,
      showIcon: openSunday,
      description: PICKUP_POINT_AMENITIES_LABELS.OPEN_SUNDAYS,
    },
  ];

  return (
    <div>
      {items
        .filter(({ showIcon }) => showIcon)
        .map(({ icon: Icon, showIcon, description }, index) => (
          <div key={index} className="mb-1 d-flex align-items-center">
            <Icon fill="#777" className="mr-2" />
            <span>{description}</span>
          </div>
        ))}
    </div>
  );
};

export default PickupAmenities;
