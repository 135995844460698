import { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import Checkbox from "~/components/Checkbox";
import PackageServiceDetailsContent from "~/components/PackageServiceDetailsContent";
import { DAY_DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import { Fields, ShipmentEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers, SettingsModels, ShipmentModels } from "~/models";
import { formatMessage } from "~/utils/string";

const PackageDetailsContent = props => {
  const {
    disabledFields,
    allowedFields,
    onGenerateCustomsDataChange,
    autofocusTotalPackages,
    filterDate,
    getDatesRange,
    customer,
  } = props;

  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );

  const dropdownRef = useRef(null);

  const handleDatePickerBlur = useCallback(
    (...data) => {
      props.onDateChange(...data);
    },
    [props.onDateChange]
  );

  const handleShipmentTypeChange = useCallback(
    (e, value) => props.onChangeShipmentType(e, value, dropdownRef),
    [props.onChangeShipmentType]
  );

  const totalPackagesInputRef = useRef();

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.10/diag_WH9qUcGGAqCIa402.html?id=1672219620239
  useEffect(() => {
    if (
      !props.disabledFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
      ] &&
      autofocusTotalPackages
    ) {
      totalPackagesInputRef.current.focus();
    }
  }, [
    props.disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS],
    autofocusTotalPackages,
  ]);

  return (
    <>
      <Row>
        <Col xs={6}>
          <Field
            maxLength={2}
            normalize={Normalizers.integerNormalize}
            component={FormControl.Input}
            label={S.TOTAL_PACKAGES_NUMBER}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS}
            onBlur={props.onFieldEntry}
            required
            helperText
            disabled={
              props.disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
              ]
            }
            inputRef={totalPackagesInputRef}
          />
        </Col>
        <Col xs={6}>
          <Field
            normalize={Normalizers.floatNormalize}
            maxLength={8}
            component={FormControl.Input}
            label={S.TOTAL_WEIGHT}
            helperText={ShipmentModels.getRoundedHelperText(
              props.selectedCountry,
              props.selectedNetworkCode
            )}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
              ]
            }
            disabled={
              props.disabledFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT
              ]
            }
          />
        </Col>
      </Row>
      {/* Display a section here only for the old version */}
      {!isNewVersion && <PackageServiceDetailsContent {...props} />}
      <Row>
        <Col xs={7}>
          <Field
            readOnly
            component={FormControl.DatePicker}
            label={S.SHIPMENT_DATE}
            name={ShipmentEntity.SHIPMENT_DATE}
            helperText={S.FORWARD_SHIPMENT_DATE}
            onBlur={handleDatePickerBlur}
            dateFormat={DAY_DEFAULT_DATE_FORMAT}
            getDatesRange={getDatesRange}
            filterDate={filterDate}
            required
            disabled={disabledFields[ShipmentEntity.SHIPMENT_DATE]}
          />
        </Col>
        <Col xs={5}>
          <Checkbox
            checked={props.rememberDate}
            label={S.REMEMBER_DATE}
            helperText={S.LOCK_SHIPMENT_DATE}
            onChange={props.onRememberDateChange}
            onBlur={props.onFieldEntry}
            disabled={disabledFields[Fields.REMEMBER_DATE]}
          />
        </Col>
      </Row>
      {allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.YOUR_REFERENCE_1}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1}
              helperText={S.MAX_25_CHARACTERS}
              maxLength={25}
              onBlur={props.onFieldEntry}
              disabled={
                disabledFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
                ]
              }
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
                ]
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_2}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2
              ]
            }
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.YOUR_REFERENCE_3}
            name={ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3}
            helperText={S.MAX_25_CHARACTERS}
            maxLength={25}
            onBlur={props.onFieldEntry}
            required={
              props.requiredFields[
                ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3
              ]
            }
            disabled={
              disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]
            }
          />
        </Col>
      </Row>
      {allowedFields[ShipmentEntity.SHIPMENT_TYPE] && (
        <Row>
          <Col>
            <Field
              inputRef={dropdownRef}
              component={FormControl.Dropdown}
              label={S.COLLECT_DELIVERY}
              name={ShipmentEntity.SHIPMENT_TYPE}
              values={props.shipmentTypes}
              helperText={formatMessage(S.PLEASE_SELECT_$, S.COLLECTION_TYPE)}
              onBlur={props.onFieldEntry}
              onChange={handleShipmentTypeChange}
              textTransform={S.UPPERCASE}
              disabled={disabledFields[ShipmentEntity.SHIPMENT_TYPE]}
            />
          </Col>
        </Row>
      )}
      {!isNewVersion && allowedFields[ShipmentEntity.GENERATE_CUSTOMS_DATA] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.GENERATE_CUSTOMS_DATA}
              placeholder={S.PLEASE_SELECT}
              name={ShipmentEntity.GENERATE_CUSTOMS_DATA}
              values={S.DEFAULT_BOOLEAN_KEY_VALUE}
              normalize={Normalizers.booleanOrNullValueNormalize}
              format={Normalizers.booleanOrNullValueFormat}
              helperText
              textTransform={S.UPPERCASE}
              disabled={disabledFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]}
              onChange={onGenerateCustomsDataChange}
              required={
                props.requiredFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
};

PackageDetailsContent.propTypes = {
  ...propTypes,
  disabledFields: PropTypes.object,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  onGenerateCustomsDataChange: PropTypes.func,
  deliveryAddress: PropTypes.object,
  pickupLocations: PropTypes.array,
};

export default PackageDetailsContent;
