import isEqual from "lodash/isEqual";
import { compose, lifecycle } from "recompose";

import { BANNERS_TYPES } from "@dpdgroupuk/mydpd-enums";

export default compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        (!prevProps.hasSubmitFailed && this.props.hasSubmitFailed) ||
        (!isEqual(prevProps.parcelsErrors, this.props.parcelsErrors) &&
          !isEqual(
            this.props.pageFormState.initial,
            this.props.pageFormState.values
          ))
      ) {
        this.props.banner.hideById(this.props.parcelsErrorsBannerId);
        this.props.setParcelsErrorsBannerId();

        if (this.props.parcelsErrors.length) {
          this.props.setParcelsErrorsBannerId(
            this.props.banner.showByType(BANNERS_TYPES.ALERT, {
              message: this.props.parcelsErrors.join("\n"),
              closable: true,
              showIcon: true,
              actions: [],
            })
          );
        }
      }
    },
  })
);
