import moment from "moment-timezone";

import * as DATE_FORMAT from "~/constants/dateFormats";

const format = (date = new Date(), outputFormat, inputFormat) =>
  moment(date, inputFormat).format(outputFormat);

export const formatTime = date => format(date, DATE_FORMAT.TIME_FORMAT);

export const formatDate = date => format(date, DATE_FORMAT.DEFAULT_DATE_FORMAT);

export const formatDateTime = (
  date,
  inputFormat = DATE_FORMAT.DEFAULT_DATE_FORMAT
) => format(date, DATE_FORMAT.DATE_TIME_FORMAT, inputFormat);

export const formatISODate = (
  date,
  inputFormat = DATE_FORMAT.DEFAULT_DATE_FORMAT
) => format(date, DATE_FORMAT.ISO_DATE_FORMAT, inputFormat);

export const formatTodayDateTime = time =>
  formatDateTime(time, DATE_FORMAT.HH_MM_TIME_FORMAT);

export const getMilliseconds = date => moment(date).valueOf();

export const chooseSmallerDate = (date1, date2) =>
  getMilliseconds(date1) > getMilliseconds(date2) ? date2 : date1;

/**
 * @param {String} seconds - date like '55728'
 * @returns {String} date like "2018-10-23T12:24:08"
 */
export const formatInternalTime = seconds => {
  const time = moment().startOf("day").add(seconds, "s");
  return format(time, DATE_FORMAT.DATE_TIME_FORMAT);
};

export const getUKCurrentMomentTime = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getCurrentDateTime = () =>
  getUKCurrentMomentTime().format(DATE_FORMAT.DATE_TIME_FORMAT);

export const calculatePositionInPeriod = (date, periodStart, periodEnd) => {
  const start = getMilliseconds(periodStart);
  const end = getMilliseconds(periodEnd);
  const value = getMilliseconds(date);
  return Math.round(((value - start) / (end - start)) * 100);
};

export const getDayOfWeek = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("ddd");
export const getDay = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("D");
export const getMontAndYear = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("MMMM YYYY");

export const isSunday = date => date.getDay() === 0;
export const isSaturday = date => date.getDay() === 6;
export const isWeekend = date => isSunday(date) || isSaturday(date);

export const isValidDate = date => moment(date).isValid();

// @see: comments from https://geopost.jira.com/browse/CSHIP-6352
export const isOldShipment = shipmentDate =>
  moment().diff(moment(shipmentDate, DATE_FORMAT.ISO_DATE_FORMAT), "days") > 9;

// pickup points date modals
export const getCurrentMomentDate = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getCurrentDay = () =>
  getCurrentMomentDate().format(DATE_FORMAT.DAY_DATE_FORMAT);

export const getTimeMomentDate = time =>
  moment.tz(time, DATE_FORMAT.TIME_FORMAT, DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getTimeString = time => {
  const hours = moment(time, DATE_FORMAT.TIME_FORMAT).format("h");
  const minutes = moment(time, DATE_FORMAT.TIME_FORMAT).format("mm");

  return `${hours}${minutes !== "00" ? `:${minutes}` : ""}${moment(
    time,
    DATE_FORMAT.TIME_FORMAT
  ).format("a")}`;
};

export const isCurrentTimeBefore = time =>
  getCurrentMomentDate().isBefore(getTimeMomentDate(time));

export const isCurrentTimeAfter = time =>
  getCurrentMomentDate().isAfter(getTimeMomentDate(time));

export const isCurrentTimeBetween = (startTime, endTime) => {
  const currentDate = getCurrentMomentDate();

  const isCurrentTimeAfterStartTime = currentDate.isSameOrAfter(
    getTimeMomentDate(startTime)
  );

  const isCurrentTimeBeforeEndTime = currentDate.isBefore(
    getTimeMomentDate(endTime)
  );

  return isCurrentTimeAfterStartTime && isCurrentTimeBeforeEndTime;
};
