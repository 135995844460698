import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { without, includes } from "lodash";

import { ALL, SHOP, LOCKER, FILTERS } from "~/constants/strings";
import {
  PICKUP_POINT_TYPE,
  PICKUP_POINT_LABEL,
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_AMENITIES_LABELS,
} from "~/constants/pickupPoint";
import { Check, Filter } from "../../Icon";

import styles from "./Filters.module.scss";

const Filters = ({
  selectedType,
  setSelectedType,
  selectedAmenities,
  setSelectedAmenities,
}) => {
  const TypeFilterButton = ({ type, label }) => {
    const isChecked = type === selectedType;

    return (
      <Dropdown.Item
        onClick={() => setSelectedType(type)}
        className={styles.dropdownButton}
      >
        <span className="mr-1">{label.toUpperCase()}</span>
        {isChecked && <Check className={styles.checkIcon} />}
      </Dropdown.Item>
    );
  };

  const AmenitiesFilterButton = ({ id, label }) => {
    const isChecked = includes(selectedAmenities, id);

    return (
      <div
        className={classNames(
          styles.dropdownButton,
          styles.amenitiesFilterButton,
          "py-2 px-4"
        )}
        onClick={() =>
          setSelectedAmenities(
            isChecked
              ? without(selectedAmenities, id)
              : [...selectedAmenities, id]
          )
        }
      >
        <span className="mr-1">{label}</span>
        {isChecked && <Check className={styles.checkIcon} />}
      </div>
    );
  };

  return (
    <div className="d-flex px-4 py-3">
      <Dropdown className="mr-2">
        <Dropdown.Toggle
          variant="outline-dark"
          id="pickup-type-filter"
          className={classNames(styles.toggleButton, "rounded")}
        >
          <span className="mr-1">
            {(
              (selectedType && PICKUP_POINT_LABEL[selectedType]) ||
              ALL
            ).toUpperCase()}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <TypeFilterButton type={null} label={ALL} />
          <TypeFilterButton type={PICKUP_POINT_TYPE.SHOP} label={SHOP} />
          <TypeFilterButton type={PICKUP_POINT_TYPE.LOCKER} label={LOCKER} />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown autoClose="inside">
        <Dropdown.Toggle
          variant="outline-dark"
          id="dropdown-autoclose-inside"
          className={classNames(
            styles.toggleButton,
            styles.amenitiesFilterToggle,
            "d-flex align-items-center rounded"
          )}
        >
          <span className="mr-1">{FILTERS.toUpperCase()}</span>
          <Filter size="20" />
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.amenitiesFilterMenu}>
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING}
            label={PICKUP_POINT_AMENITIES_LABELS.LABEL_PRINTING}
          />
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE}
            label={PICKUP_POINT_AMENITIES_LABELS.OPEN_LATE}
          />
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING}
            label={PICKUP_POINT_AMENITIES_LABELS.CAR_PARKING}
          />
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS}
            label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SATURDAYS}
          />
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS}
            label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SUNDAYS}
          />
          <AmenitiesFilterButton
            id={PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS}
            label={PICKUP_POINT_AMENITIES_LABELS.WHEELCHAIR_ACCESS}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Filters;
