import { SHOP, LOCKER } from "~/constants/strings";

export const PICKUP_POINT_TYPE = {
  SHOP: "SHP",
  LOCKER: "LKR",
};

export const PICKUP_POINT_LABEL = {
  [PICKUP_POINT_TYPE.SHOP]: SHOP,
  [PICKUP_POINT_TYPE.LOCKER]: LOCKER,
};

export const PICKUP_POINT_AMENITIES_TYPE = {
  LABEL_PRINTING: "dropOffDpd",
  OPEN_LATE: "openLate",
  CAR_PARKING: "parkingAvailable",
  OPEN_SATURDAYS: "openSaturday",
  OPEN_SUNDAYS: "openSunday",
  WHEELCHAIR_ACCESS: "disabledAccess",
};

export const PICKUP_POINT_AMENITIES_LABELS = {
  LABEL_PRINTING: "Label Printing",
  OPEN_LATE: "Open Late",
  CAR_PARKING: "Parking Available",
  OPEN_SATURDAYS: "Open Saturdays",
  OPEN_SUNDAYS: "Open Sundays",
  WHEELCHAIR_ACCESS: "Wheelchair Access",
};
