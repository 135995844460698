import { useState, useEffect } from "react";
import classNames from "classnames";
import { sortBy, round, includes, find } from "lodash";
import { Button } from "@dpdgroupuk/mydpd-ui";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

import {
  SEE_MORE,
  _$_MILES,
  SELECT_PICKUP_LOCATION,
  CHANGE,
} from "~/constants/strings";
import SelectPickupPointModal from "../SelectPickupPointModal";
import {
  getPickupPointType,
  getPickupPointAddress,
  getPickupLocationCode,
} from "~/models/pickupPoint";
import { formatMessage } from "~/utils/string";

import styles from "./SelectPickupLocationList.module.scss";

const PickupLocation = ({
  pickupPoint,
  onPickupLocationChange,
  selectedPickupPoint,
}) => {
  const pickupLocationCode = getPickupLocationCode(pickupPoint);

  return (
    <Form.Check
      key={pickupLocationCode}
      id={pickupLocationCode}
      type="radio"
      onChange={() => onPickupLocationChange(pickupPoint)}
      value={pickupLocationCode}
      checked={
        pickupLocationCode === getPickupLocationCode(selectedPickupPoint)
      }
      className={classNames(styles.radio, "mb-1")}
      label={
        <span>
          <span>{`${getPickupPointType(pickupPoint).toUpperCase()} - ${getPickupPointAddress(pickupPoint)} `}</span>
          <span
            className={classNames(styles.title)}
          >{`(${formatMessage(_$_MILES, round(pickupPoint.distance, 1))})`}</span>
        </span>
      }
    />
  );
};

PickupLocation.propTypes = {
  pickupPoint: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
  selectedPickupPoint: PropTypes.object,
};

const SelectPickupLocationList = ({
  deliveryAddress,
  pickupPoints = [],
  input,
  onPickupLocationChange,
}) => {
  const selectedPickupPoint = find(pickupPoints, {
    pickupLocation: {
      pickupLocationCode: input.value,
    },
  });

  const [displaySelectPickupModal, setDisplaySelectPickupModal] =
    useState(false);

  const [extraPickupLocation, setExtraPickupLocation] = useState(false);

  const closestPickupPoints = sortBy(pickupPoints, "distance").slice(0, 3);
  const closestPickupLocationCodes = closestPickupPoints.map(pickupPoints =>
    getPickupLocationCode(pickupPoints)
  );

  const handlePickupLocationChange = pickupPoint => {
    input.onChange(pickupPoint.pickupLocation.pickupLocationCode);
    onPickupLocationChange(pickupPoint);
  };

  useEffect(() => {
    const selectedPickupPointCode = getPickupLocationCode(selectedPickupPoint);

    if (!includes(closestPickupLocationCodes, selectedPickupPointCode)) {
      setExtraPickupLocation(selectedPickupPoint);
    }
  }, [selectedPickupPoint, closestPickupLocationCodes]);

  return (
    <Row>
      <Col>
        <div className={classNames(styles.title, "mb-1")}>
          {SELECT_PICKUP_LOCATION}
        </div>
        <>
          {closestPickupPoints.map((pickupPoint, index) => (
            <PickupLocation
              pickupPoint={pickupPoint}
              selectedPickupPoint={selectedPickupPoint}
              onPickupLocationChange={handlePickupLocationChange}
            />
          ))}
          {extraPickupLocation && (
            <PickupLocation
              pickupPoint={extraPickupLocation}
              selectedPickupPoint={selectedPickupPoint}
              onPickupLocationChange={handlePickupLocationChange}
            />
          )}
        </>
        {displaySelectPickupModal && (
          <SelectPickupPointModal
            deliveryAddress={deliveryAddress}
            pickupPoints={pickupPoints}
            onHide={() => setDisplaySelectPickupModal(false)}
            selectedPickupPoint={selectedPickupPoint}
            onPickupLocationChange={handlePickupLocationChange}
            open
          />
        )}
      </Col>
      <Button
        variant="dark"
        onClick={() => setDisplaySelectPickupModal(true)}
        className={classNames(styles.seeMoreButton, "mb-1")}
      >
        {extraPickupLocation ? CHANGE : SEE_MORE}
      </Button>
    </Row>
  );
};

SelectPickupLocationList.propTypes = {
  deliveryAddress: PropTypes.object,
  pickupPoints: PropTypes.array,
  input: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
};

export default SelectPickupLocationList;
