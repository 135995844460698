import get from "lodash/get";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";
import { useMemo } from "react";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import Customs from "~/components/Customs";
import DomesticService from "~/components/DomesticService";
import ExtendedLiability from "~/components/ExtendedLiability";
import { ReduxifiedCheckbox } from "~/components/Checkbox";
import SelectPickupLocationList from "~/components/SelectPickupLocationList";
import {
  OUTBOUND_CONSIGNMENT_UPPERCASE,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers, SettingsModels } from "~/models";
import { formatMessage } from "~/utils/string";

const PackageServiceDetailsContent = props => {
  const {
    requiredFields,
    currencies,
    onFieldEntry,
    changeLiabilityValue,
    disabledFields,
    allowedFields,
    onNetworkChange,
    onLiabilityChange,
    onImporterTypeChange,
    onAtRiskChange,
    customer,
    onGenerateCustomsDataChange,
    deliveryAddress,
    pickupLocations,
    onPickupLocationChange,
  } = props;
  const selectedCurrency = get(
    props,
    "createShipmentValues.outboundConsignment.currency",
    S.GBP
  );
  const isNewVersion = useMemo(
    () => SettingsModels.isNewVersion(customer.shippingVersion),
    [customer.shippingVersion]
  );

  return (
    <>
      {isNewVersion && allowedFields[ShipmentEntity.GENERATE_CUSTOMS_DATA] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.GENERATE_CUSTOMS_DATA}
              placeholder={S.PLEASE_SELECT}
              name={ShipmentEntity.GENERATE_CUSTOMS_DATA}
              values={S.DEFAULT_BOOLEAN_KEY_VALUE}
              normalize={Normalizers.booleanOrNullValueNormalize}
              format={Normalizers.booleanOrNullValueFormat}
              helperText
              textTransform={S.UPPERCASE}
              disabled={disabledFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]}
              onChange={onGenerateCustomsDataChange}
              required={
                props.requiredFields[ShipmentEntity.GENERATE_CUSTOMS_DATA]
              }
            />
          </Col>
        </Row>
      )}
      {allowedFields.domesticService ? (
        <Field
          component={DomesticService}
          name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE}
          shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
          values={props.outboundServices}
          disabled={disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]}
          serviceDisabled={
            disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]
          }
          helperText={formatMessage(S.PLEASE_SELECT_$, S.YOUR_PRODUCT_SERVICE)}
          onBlur={props.onFieldEntry}
          required={
            props.requiredFields[
              ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
            ]
          }
          textTransform={S.UPPERCASE}
          onChange={onNetworkChange}
        />
      ) : (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.PRODUCT_SERVICE}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE}
              values={props.networksKeyValue}
              disabled={
                disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]
              }
              helperText={formatMessage(
                S.PLEASE_SELECT_$,
                S.YOUR_PRODUCT_SERVICE
              )}
              onBlur={props.onFieldEntry}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
                ]
              }
              textTransform={S.UPPERCASE}
              onChange={onNetworkChange}
            />
          </Col>
        </Row>
      )}
      {allowedFields.shipToShop && (
        <Row className="mb-4">
          <Col>
            <Field
              name={
                ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS
                  .PICKUP_LOCATION_CODE
              }
              shipmentSection={OUTBOUND_CONSIGNMENT_UPPERCASE}
              component={SelectPickupLocationList}
              deliveryAddress={deliveryAddress}
              pickupPoints={pickupLocations}
              onPickupLocationChange={onPickupLocationChange}
            />
          </Col>
        </Row>
      )}
      {!isNewVersion && allowedFields.customs && (
        <Customs
          requiredFields={requiredFields}
          currencies={currencies}
          onFieldEntry={onFieldEntry}
          disabledFields={disabledFields}
          allowedFields={allowedFields}
        />
      )}
      {allowedFields.extendedLiability && (
        <ExtendedLiability
          selectedCurrency={selectedCurrency}
          requiredFields={props.requiredFields}
          changeLiabilityValue={changeLiabilityValue}
          onFieldEntry={onFieldEntry}
          allowedFields={allowedFields}
          disabledFields={disabledFields}
          onLiabilityChange={onLiabilityChange}
        />
      )}
      {allowedFields[ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS] && (
        <Row>
          <Col xs={6}>
            <Field
              component={FormControl.Dropdown}
              label={S.IMPORTER_TYPE}
              name={ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS}
              onBlur={(event, newValue, previousValue, name) => {
                props.onFieldEntry(event, newValue, previousValue, name);
                event.preventDefault();
              }}
              onChange={(_, newValue, previousValue) =>
                onImporterTypeChange(newValue, previousValue)
              }
              required={
                props.requiredFields[
                  ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
                ]
              }
              values={S.IMPORTER_TYPE_KEY_VALUE}
              helperText={S.IS_GOING_TO_BUSINESS_OR_CONSUMER}
              textTransform={S.UPPERCASE}
              normalize={Normalizers.convertToBool}
            />
          </Col>
          {allowedFields[ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK] && (
            <Col xs={6}>
              <Field
                type="checkbox"
                component={ReduxifiedCheckbox}
                name={ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK}
                onChange={(_, newValue) => onAtRiskChange(newValue)}
                label={S.AT_RISK_OF_EU}
                onBlur={(event, newValue, previousValue, name) => {
                  props.onFieldEntry(event, newValue, previousValue, name);
                  event.preventDefault();
                }}
              />
            </Col>
          )}
        </Row>
      )}
      {isNewVersion && allowedFields.customs && (
        <Customs
          requiredFields={requiredFields}
          currencies={currencies}
          onFieldEntry={onFieldEntry}
          disabledFields={disabledFields}
          allowedFields={allowedFields}
        />
      )}
      {allowedFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Input}
              label={S.DEST_TAX_ID_REG_NUMBER}
              name={
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
              }
              onBlur={props.onFieldEntry}
              maxLength={45}
              helperText={S.MAX_45_CHARACTERS}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
                ]
              }
              onChange={props.onGstChange}
              disabled={
                disabledFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
                ]
              }
            />
          </Col>
        </Row>
      )}
      {allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID] && (
        <Row>
          <Col>
            <Field
              component={FormControl.Dropdown}
              label={S.GST_VAT_PAID}
              name={ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID}
              values={S.DEFAULT_BOOLEAN_KEY_VALUE}
              onBlur={props.onFieldEntry}
              helperText={S.PLEASE_SELECT}
              required={
                props.requiredFields[
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID
                ]
              }
              textTransform={S.UPPERCASE}
              normalize={Normalizers.booleanOrNullValueNormalize}
              format={Normalizers.booleanOrNullValueFormat}
              disabled={
                disabledFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
};

PackageServiceDetailsContent.propTypes = {
  ...propTypes,
  disabledFields: PropTypes.object,
  customer: PropTypes.object,
  onGstChange: PropTypes.func.isRequired,
  onLiabilityChange: PropTypes.func.isRequired,
  onImporterTypeChange: PropTypes.func,
  onAtRiskChange: PropTypes.func,
  onGenerateCustomsDataChange: PropTypes.func,
};

export default PackageServiceDetailsContent;
