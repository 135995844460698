const PickupShopPin = props => (
  <svg viewBox="0 0 36 39" width="2.7rem" height="3.4rem" {...props}>
    <g filter="url(#filter0_d_3540_3350)">
      <rect
        x="3.13477"
        y="3.1232"
        width="29.6073"
        height="29.4117"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00195 32.6659H32.8732V3H3.00195V32.6659ZM32.6092 32.402H3.26201V3.25419H32.6092V32.402Z"
        fill="#E9E9EA"
      />
      <path
        d="M32.8712 20.3709H3V32.6679H15.555L17.9894 35.2821L20.4237 32.6679H32.8712V20.3709Z"
        fill="#DC0032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6037 14.0925C28.0791 14.0958 28.5532 14.0413 29.0154 13.9302V8.98715H28.0847V10.5846C27.8769 10.5034 27.6545 10.4661 27.4316 10.4751C26.3523 10.4751 25.6151 11.1908 25.6151 12.2916C25.6151 13.457 26.407 14.0925 27.6037 14.0925ZM21.372 13.9321C20.909 14.0431 20.4343 14.0976 19.9583 14.0944C18.7616 14.0944 17.9697 13.4589 17.9697 12.2936C17.9697 11.1927 18.7088 10.4771 19.7882 10.4771C20.0105 10.4679 20.2322 10.5052 20.4393 10.5866V8.98716H21.372V13.9321ZM20.4393 11.4078C20.258 11.3295 20.0618 11.2921 19.8644 11.2983C19.2974 11.2983 18.9181 11.6483 18.9181 12.2623C18.9181 12.9212 19.3287 13.2947 19.9896 13.2947C20.1404 13.2956 20.2909 13.2825 20.4393 13.2556V11.4078ZM28.0943 11.4078C27.913 11.3298 27.7168 11.2924 27.5194 11.2983C26.9524 11.2983 26.5731 11.6483 26.5731 12.2623C26.5731 12.9212 26.9837 13.2947 27.6426 13.2947C27.7941 13.2956 27.9453 13.2825 28.0943 13.2556V11.4078ZM23.4037 11.3218C23.2143 11.3173 23.0257 11.3464 22.8464 11.4079L22.8523 15.4162H21.9118V10.7704C22.3948 10.5818 22.9086 10.4844 23.4272 10.483C24.5593 10.483 25.3277 11.1282 25.3277 12.2232C25.3277 13.4257 24.6062 14.1003 23.4037 14.1003H23.3568V13.285C23.9434 13.2771 24.3814 12.9584 24.3814 12.2545C24.3814 11.6562 23.9786 11.3218 23.4037 11.3218Z"
        fill="#414042"
      />
      <path
        d="M11.1714 6.94972L7.4348 8.98715L11.6211 11.4235C11.6615 11.449 11.6865 11.493 11.6876 11.5408V15.0955C11.685 15.1434 11.6585 15.1867 11.6172 15.2109L11.3923 15.336C11.3711 15.3465 11.3476 15.3519 11.3239 15.3517C11.2988 15.3527 11.2739 15.3474 11.2516 15.336L11.0267 15.2109C10.9848 15.1872 10.9582 15.1436 10.9563 15.0955V11.926C10.9535 11.9007 10.94 11.8779 10.9192 11.8634L7.23145 9.71257V13.9223C7.23508 14.0248 7.28553 14.12 7.36832 14.1804L11.1733 16.4193C11.2661 16.4663 11.3758 16.4663 11.4686 16.4193L15.2658 14.1804C15.353 14.1224 15.408 14.0269 15.4144 13.9223V9.71648L12.9155 11.1732C12.8729 11.1965 12.8213 11.1965 12.7787 11.1732L12.5557 11.0402C12.5364 11.028 12.5202 11.0112 12.5088 10.9913C12.4961 10.9698 12.4881 10.9458 12.4854 10.921V10.6629C12.4872 10.6148 12.5119 10.5704 12.5518 10.5436L15.215 8.99302L11.4745 6.94972C11.4276 6.92706 11.376 6.91568 11.3239 6.91648C11.2724 6.9157 11.2215 6.92709 11.1753 6.94972"
        fill="#DC0032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0112 24.8916L17.8389 26.5634L20.1324 28.4659V28.5148H19.7414L17.5104 26.6338V28.5148H17.1878V23.5307H17.5026V26.4852L19.6338 24.8427H20.0112V24.8916ZM11.9123 23.5503C12.0492 23.5503 12.1411 23.6148 12.1411 23.7888C12.1411 23.9629 12.0492 24.0352 11.9123 24.0352C11.8452 24.043 11.7783 24.0194 11.7309 23.9713C11.6835 23.9231 11.661 23.8558 11.6699 23.7888C11.6612 23.7226 11.6841 23.6561 11.7317 23.6092C11.7794 23.5623 11.8462 23.5405 11.9123 23.5503ZM11.7481 24.8427H12.0609V28.5148H11.7481V24.8427ZM16.3235 25.2436C15.9413 24.9117 15.4492 24.7339 14.9431 24.745C13.7797 24.745 12.9643 25.5721 12.9643 26.6768C12.9524 27.2015 13.1585 27.7076 13.5335 28.0748C13.9084 28.442 14.4188 28.6374 14.9431 28.6145C15.4521 28.627 15.9474 28.4484 16.3313 28.114L16.1671 27.8754C15.8351 28.1714 15.4034 28.3307 14.9587 28.3212C14.0538 28.3212 13.3202 27.5876 13.3202 26.6827C13.3202 25.7777 14.0538 25.0441 14.9587 25.0441C15.4028 25.0336 15.8345 25.1915 16.1671 25.486L16.3235 25.2436ZM23.7498 27.7483V28.5148H24.0646V24.8427H23.7498V27.2654C23.5758 27.8961 22.9876 28.3217 22.3341 28.29C21.4699 28.29 21.069 27.6936 21.069 26.9212V24.8427H20.7542V26.9564C20.7542 27.8754 21.2489 28.5774 22.2637 28.5774C22.878 28.6127 23.4572 28.2896 23.7498 27.7483ZM25.5369 25.6366C25.8554 25.2693 26.3159 25.0558 26.802 25.05C27.7679 25.05 28.3897 25.7246 28.3897 26.6788C28.3897 27.633 27.7601 28.2939 26.802 28.2939C26.5842 28.2928 26.3685 28.2503 26.1665 28.1687L26.1665 28.1785L26.1665 28.4425C26.3847 28.5302 26.6177 28.5753 26.8528 28.5754C27.9752 28.5754 28.7045 27.7816 28.7045 26.6788C28.7045 25.576 27.9752 24.7704 26.8528 24.7704C26.3585 24.7746 25.8857 24.9734 25.5369 25.3237V24.833H25.2221V29.8249H25.5369V25.6366Z"
        fill="white"
      />
      <path
        d="M9.29619 23.7399H7.28809V28.5148H7.60289V27.0112V26.7315V24.0215H9.26099C10.0196 24.0215 10.6473 24.4575 10.6473 25.3902C10.6473 26.2408 10.0196 26.7315 9.26099 26.7315H8.23445V27.0112H9.29619C10.1976 27.0112 10.9699 26.4089 10.9699 25.3902C10.9699 24.26 10.1976 23.7399 9.29619 23.7399Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3540_3350"
        x="0.358491"
        y="1.01887"
        width="35.1561"
        height="37.5651"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.660377" />
        <feGaussianBlur stdDeviation="1.32075" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.25098 0 0 0 0 0.258824 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3540_3350"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3540_3350"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default PickupShopPin;
