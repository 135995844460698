const PickupPoint = props => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" {...props}>
    <g filter="url(#filter0_ddd_3540_1778)">
      <g clipPath="url(#clip0_3540_1778)">
        <path d="M57 2H3V33.7588H57V2Z" fill="white" />
        <path
          d="M35.6747 22.2586C34.9595 22.4478 34.0284 22.5414 33.2183 22.5414C31.1395 22.5414 29.7632 21.4351 29.7632 19.4105C29.7632 17.4947 31.0452 16.2535 32.9215 16.2535C33.3399 16.2535 33.7851 16.307 34.0551 16.442V13.6607H35.6747V22.2586ZM34.0551 17.8731C33.7985 17.7514 33.4616 17.6839 33.0558 17.6839C32.0713 17.6839 31.4095 18.2915 31.4095 19.3576C31.4095 20.5047 32.1247 21.153 33.2717 21.153C33.4743 21.153 33.7844 21.139 34.0545 21.0855V17.8731H34.0551ZM48.9637 22.2586C48.2478 22.4478 47.3167 22.5414 46.5072 22.5414C44.4291 22.5414 43.0521 21.4351 43.0521 19.4105C43.0521 17.4947 44.3348 16.2535 46.2111 16.2535C46.6295 16.2535 47.0747 16.307 47.3447 16.442V13.6607H48.9643V22.2586H48.9637ZM47.3441 17.8731C47.0874 17.7514 46.7499 17.6839 46.3448 17.6839C45.3595 17.6839 44.6985 18.2915 44.6985 19.3576C44.6985 20.5047 45.4137 21.153 46.5607 21.153C46.7632 21.153 47.0734 21.139 47.3434 21.0855V17.8731H47.3441ZM38.2214 17.8597C38.4908 17.7514 38.8685 17.7113 39.1926 17.7113C40.1919 17.7113 40.8797 18.2915 40.8797 19.3302C40.8797 20.5555 40.1171 21.1096 39.0977 21.1243V22.5414C39.1245 22.5414 39.1519 22.542 39.1793 22.542C41.2708 22.542 42.5261 21.3683 42.5261 19.2768C42.5261 17.3738 41.1906 16.2542 39.2194 16.2542C38.2208 16.2542 37.2355 16.4834 36.5878 16.7535V24.8374H38.2214V17.8597Z"
          fill="#414042"
        />
        <path
          d="M18.2034 26.6435C18.2964 26.6428 18.3893 26.6234 18.4595 26.582L25.0622 22.6884C25.2032 22.6049 25.3182 22.4043 25.3182 22.2406V14.9313L20.9741 17.4627C20.9072 17.5014 20.8023 17.4988 20.7368 17.458L20.3518 17.2294C20.3203 17.21 20.2916 17.1786 20.2702 17.1412C20.2689 17.1392 20.2675 17.1365 20.2662 17.1345C20.2415 17.0944 20.2274 17.0523 20.2261 17.0135L20.2161 16.5643C20.2134 16.4888 20.2629 16.3965 20.3304 16.3571L24.9585 13.6607L18.4635 10.1207C18.3919 10.0813 18.2977 10.0619 18.2034 10.0619H18.2021C18.1079 10.0619 18.0143 10.0813 17.9428 10.1207L11.4477 13.6607L18.7235 17.8965C18.791 17.9332 18.8425 18.0208 18.8425 18.0997V24.2786C18.8425 24.3561 18.787 24.445 18.7188 24.4818L18.3271 24.699C18.2944 24.7164 18.2529 24.7258 18.2101 24.7258H18.2028C18.156 24.7271 18.1119 24.7177 18.0778 24.699L17.6848 24.4818C17.6179 24.4464 17.5631 24.3568 17.5631 24.2786V18.7701C17.5598 18.73 17.5303 18.6805 17.4976 18.6618L11.0874 14.93V22.2392C11.0874 22.403 11.2024 22.6049 11.3441 22.6871L17.9461 26.5806C18.0176 26.6234 18.1105 26.6441 18.2034 26.6435Z"
          fill="url(#paint0_linear_3540_1778)"
        />
        <path d="M57 33.7588H3V56H57V33.7588Z" fill="#DC0032" />
        <path
          d="M19.2666 39.1477C19.5066 39.1477 19.671 39.2593 19.671 39.5701C19.671 39.881 19.5066 40.0046 19.2666 40.0046C19.0139 40.0046 18.8368 39.8803 18.8368 39.5701C18.8368 39.26 19.0133 39.1477 19.2666 39.1477ZM18.9758 41.4324H19.532V47.7109V47.9268H18.9758V41.4324ZM24.6267 41.2586C25.6126 41.2586 26.4595 41.5935 27.0664 42.1402L26.7757 42.5627C26.245 42.0787 25.5244 41.7806 24.6394 41.7806C22.8948 41.7806 21.6937 43.0226 21.6937 44.6736C21.6937 46.3252 22.8948 47.5792 24.6394 47.5792C25.5244 47.5792 26.245 47.2811 26.7757 46.7971L27.0664 47.2196C26.4595 47.7657 25.6126 48.1012 24.6267 48.1012C22.5659 48.1012 21.1248 46.624 21.1248 44.6742C21.1248 42.7238 22.5659 41.2586 24.6267 41.2586ZM33.5896 41.5193L29.7462 44.4744L33.8042 47.8392V47.9261H33.0963L29.1519 44.5987V47.9261H28.5958V39.1109H29.1519V44.3387L32.9192 41.433H33.5889V41.5193H33.5896ZM40.201 47.9268V46.5732C39.7204 47.4923 38.798 48.0384 37.5714 48.0384C35.776 48.0384 34.9037 46.7965 34.9037 45.1702V41.433H35.4599V45.108C35.4599 46.4736 36.1677 47.517 37.6977 47.517C39.0125 47.517 39.9356 46.7223 40.201 45.7043V41.433H40.7571V47.9268H40.201ZM43.3613 42.8361C43.7784 42.3394 44.5999 41.8181 45.5992 41.8181C47.3057 41.8181 48.4059 43.0099 48.4059 44.699C48.4059 46.3754 47.2936 47.5551 45.5992 47.5551C45.1948 47.5551 44.8138 47.4689 44.4762 47.3339V47.8178C44.8392 47.9622 45.2469 48.0518 45.6874 48.0518C47.672 48.0518 48.9614 46.6488 48.9614 44.699C48.9614 42.7244 47.672 41.3214 45.6874 41.3214C44.6888 41.3214 43.8673 41.7933 43.3613 42.302V41.433H42.8052V50.2616H43.3613V42.8361ZM44.4762 47.8172V47.3332V47.8172ZM14.6391 39.4833H11.0864V47.9268H11.6426V45.2698H11.6439V44.7732H11.6426V39.9806H14.5756C15.9158 39.9806 17.028 40.7506 17.028 42.4016C17.028 43.9042 15.9158 44.7732 14.5756 44.7732H12.7588V45.2698H14.6391C16.232 45.2698 17.5975 44.2017 17.5975 42.4016C17.5975 40.4023 16.232 39.4833 14.6391 39.4833Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddd_3540_1778"
        x="0"
        y="0"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_3540_1778"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3540_1778"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3540_1778"
          result="effect2_dropShadow_3540_1778"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_3540_1778"
          result="effect3_dropShadow_3540_1778"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_3540_1778"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3540_1778"
        x1="11.1578"
        y1="24.1344"
        x2="18.3628"
        y2="18.0887"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A90034" />
        <stop offset="0.1724" stopColor="#B40034" />
        <stop offset="0.6989" stopColor="#D10032" />
        <stop offset="1" stopColor="#DC0032" />
      </linearGradient>
      <clipPath id="clip0_3540_1778">
        <path
          d="M3 10C3 5.58172 6.58172 2 11 2H49C53.4183 2 57 5.58172 57 10V48C57 52.4183 53.4183 56 49 56H11C6.58172 56 3 52.4183 3 48V10Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PickupPoint;
