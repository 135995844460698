import { get } from "lodash";

import { joinStringsWithComma } from "~/utils/string";
import { getPickupLocationCode } from "~/models/pickupPoint";
import PickupDetails from "../PickupDetails/PickupDetails";

import styles from "./PickupPointsList.module.scss";

const PickupPointsList = ({ pickupPoints, setActivePickupPoint }) => (
  <div className={styles.container}>
    {pickupPoints.map(pickupPoint => (
      <div
        key={getPickupLocationCode(pickupPoint)}
        onClick={() => setActivePickupPoint(pickupPoint)}
        className={styles.itemContainer}
      >
        <h6>{get(pickupPoint, "pickupLocation.address.organisation")}</h6>
        <div>
          <span className={styles.address}>
            {joinStringsWithComma([
              get(pickupPoint, "pickupLocation.address.street"),
              get(pickupPoint, "pickupLocation.address.city"),
              get(pickupPoint, "pickupLocation.address.postcode"),
            ])}
          </span>
        </div>
        <PickupDetails pickupPoint={pickupPoint} />
      </div>
    ))}
  </div>
);

export default PickupPointsList;
