const PickupPinLocker = props => (
  <svg width="2.7rem" height="3.4rem" viewBox="0 0 37 39" {...props}>
    <g filter="url(#filter0_d_3540_3)">
      <rect
        x="3.69832"
        y="3.34106"
        width="29.6073"
        height="29.4117"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56536 32.8838H33.4366V3.21788H3.56536V32.8838ZM33.1726 32.6198H3.82542V3.47207H33.1726V32.6198Z"
        fill="#E9E9EA"
      />
      <path
        d="M33.4346 20.5888H3.56341V32.8857H16.1184L18.5528 35.5L20.9872 32.8857H33.4346V20.5888Z"
        fill="#DC0032"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72519 5.4891H27.6581C27.8248 5.4891 27.9847 5.55389 28.1026 5.66922C28.2205 5.78455 28.2867 5.94098 28.2867 6.10408V18.203H9.09654V6.10408C9.09654 5.76444 9.378 5.4891 9.72519 5.4891Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92453 18.3712H28.4522V6.10404C28.4522 5.67396 28.0977 5.32431 27.6581 5.32076H9.72522C9.28301 5.32076 8.92453 5.67144 8.92453 6.10404V18.3712ZM28.1147 18.0346H9.26197L9.26859 6.10404C9.26859 5.85735 9.47301 5.65737 9.72518 5.65737H27.6581C27.9102 5.65737 28.1147 5.85735 28.1147 6.10404V18.0346Z"
      fill="#808285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5211 18.3713C16.4287 18.3678 16.3556 18.2934 16.3557 18.203V5.62504C16.3421 5.55827 16.371 5.49007 16.4289 5.45225C16.4867 5.41443 16.5622 5.41443 16.62 5.45225C16.6779 5.49007 16.7068 5.55827 16.6932 5.62504V18.203C16.6932 18.2959 16.6162 18.3713 16.5211 18.3713Z"
      fill="#808285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8555 18.3712C20.8099 18.3712 20.7662 18.3535 20.7339 18.3219C20.7016 18.2904 20.6835 18.2476 20.6835 18.2029V5.48906C20.6835 5.39611 20.7605 5.32076 20.8555 5.32076C20.9506 5.32076 21.0276 5.39611 21.0276 5.48906V18.2029C21.0276 18.2959 20.9506 18.3712 20.8555 18.3712Z"
      fill="#808285"
    />
    <rect
      x="9.09654"
      y="9.72919"
      width="7.42461"
      height="4.24011"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.92451 14.1311H16.6866V9.56084H8.92451V14.1311ZM16.3557 13.7816H9.26856V9.89749H16.3557V13.7816Z"
      fill="#808285"
    />
    <rect
      x="20.8555"
      y="9.72919"
      width="7.42461"
      height="4.24011"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7165 14.1311H28.4521V9.56084H20.7165V14.1311ZM28.1147 13.7816H21.0276V9.89749H28.1147V13.7816Z"
      fill="#808285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6181 14.1312H17.7586C17.6781 14.1155 17.6202 14.0464 17.6202 13.9661C17.6202 13.8858 17.6781 13.8167 17.7586 13.801H19.6181C19.6863 13.7877 19.7561 13.816 19.7947 13.8726C19.8334 13.9292 19.8334 14.003 19.7947 14.0596C19.7561 14.1162 19.6863 14.1444 19.6181 14.1312Z"
      fill="#808285"
    />
    <rect
      x="17.5931"
      y="9.03655"
      width="2.19694"
      height="3.40503"
      fill="#CAC4BE"
    />
    <rect
      x="8.92451"
      y="16.9535"
      width="19.5277"
      height="1.41121"
      fill="#808285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.095 5.6574L10.109 6.18175L13.014 7.83895C13.0461 7.85202 13.067 7.88266 13.067 7.91663V10.3312C13.064 10.3643 13.0441 10.3936 13.014 10.4089L12.8618 10.4931H12.8089H12.7559L12.6037 10.4089C12.5737 10.3936 12.5537 10.3643 12.5508 10.3312V8.17557C12.556 8.16311 12.556 8.14919 12.5508 8.13673L9.96344 6.66078V9.51557C9.96749 9.58724 10.007 9.65247 10.0693 9.69036L12.7162 11.2116C12.7489 11.2191 12.7828 11.2191 12.8155 11.2116C12.8501 11.2213 12.8868 11.2213 12.9214 11.2116L15.5683 9.69036C15.6306 9.65247 15.6701 9.58724 15.6742 9.51557V6.66078L13.9338 7.64475C13.9061 7.6642 13.8689 7.6642 13.8412 7.64475L13.689 7.55412C13.6749 7.54683 13.6633 7.53555 13.6559 7.52175C13.6503 7.50492 13.6503 7.4868 13.6559 7.46996V7.30813C13.6517 7.27674 13.6674 7.24601 13.6956 7.23045L15.5484 6.17527L14.5625 5.65092L11.095 5.6574Z"
      fill="#DC0032"
    />
    <path
      d="M6.61321 28.9075V23.7737H6.9454V28.6055H9.9955V28.9075H6.61321Z"
      fill="white"
    />
    <path
      d="M10.4835 26.9446C10.4835 26.6426 10.5339 26.3658 10.6345 26.1141C10.7402 25.8574 10.8837 25.636 11.0649 25.4497C11.2511 25.2635 11.47 25.1175 11.7217 25.0118C11.9784 24.9061 12.2602 24.8533 12.5673 24.8533C12.8743 24.8533 13.1561 24.9061 13.4128 25.0118C13.6746 25.1175 13.896 25.2635 14.0772 25.4497C14.2634 25.636 14.4069 25.8574 14.5075 26.1141C14.6132 26.3658 14.6661 26.6426 14.6661 26.9446C14.6661 27.2415 14.6132 27.5158 14.5075 27.7675C14.4069 28.0192 14.2634 28.2381 14.0772 28.4243C13.896 28.6106 13.6746 28.7565 13.4128 28.8622C13.1561 28.9629 12.8743 29.0132 12.5673 29.0132C12.2602 29.0132 11.9784 28.9629 11.7217 28.8622C11.47 28.7565 11.2511 28.6106 11.0649 28.4243C10.8837 28.2381 10.7402 28.0192 10.6345 27.7675C10.5339 27.5158 10.4835 27.2415 10.4835 26.9446ZM10.8082 26.9446C10.8082 27.1962 10.8509 27.4278 10.9365 27.6392C11.0221 27.8505 11.1429 28.0368 11.2989 28.1978C11.4549 28.3539 11.6411 28.4772 11.8576 28.5678C12.074 28.6533 12.3106 28.6961 12.5673 28.6961C12.829 28.6961 13.0681 28.6533 13.2845 28.5678C13.5059 28.4772 13.6947 28.3539 13.8507 28.1978C14.0067 28.0368 14.1275 27.8505 14.2131 27.6392C14.2987 27.4278 14.3414 27.1962 14.3414 26.9446C14.3414 26.6929 14.2987 26.4589 14.2131 26.2425C14.1275 26.026 14.0067 25.8398 13.8507 25.6838C13.6947 25.5227 13.5059 25.3969 13.2845 25.3063C13.0681 25.2157 12.829 25.1704 12.5673 25.1704C12.3106 25.1704 12.074 25.2157 11.8576 25.3063C11.6411 25.3969 11.4549 25.5227 11.2989 25.6838C11.1429 25.8398 11.0221 26.026 10.9365 26.2425C10.8509 26.4589 10.8082 26.6929 10.8082 26.9446Z"
      fill="white"
    />
    <path
      d="M15.4832 26.9295C15.4832 26.6325 15.5335 26.3582 15.6342 26.1066C15.7399 25.8499 15.8833 25.6309 16.0645 25.4497C16.2507 25.2635 16.4722 25.1175 16.7289 25.0118C16.9856 24.9061 17.2674 24.8533 17.5745 24.8533C17.8714 24.8533 18.1432 24.9011 18.3898 24.9967C18.6365 25.0924 18.8504 25.2232 19.0316 25.3893L18.8579 25.646C18.6968 25.5001 18.5106 25.3843 18.2992 25.2987C18.0878 25.2132 17.8488 25.1704 17.582 25.1704C17.3203 25.1704 17.0812 25.2157 16.8648 25.3063C16.6483 25.3918 16.4621 25.5152 16.3061 25.6762C16.1551 25.8322 16.0368 26.0185 15.9513 26.2349C15.8657 26.4463 15.8229 26.6778 15.8229 26.9295C15.8229 27.1811 15.8657 27.4152 15.9513 27.6316C16.0368 27.843 16.1551 28.0292 16.3061 28.1903C16.4621 28.3463 16.6483 28.4696 16.8648 28.5602C17.0812 28.6508 17.3203 28.6961 17.582 28.6961C17.8488 28.6961 18.0878 28.6533 18.2992 28.5678C18.5106 28.4822 18.6968 28.3664 18.8579 28.2205L19.0316 28.4772C18.8504 28.6433 18.6365 28.7741 18.3898 28.8698C18.1432 28.9654 17.8714 29.0132 17.5745 29.0132C17.2674 29.0132 16.9856 28.9604 16.7289 28.8547C16.4722 28.749 16.2507 28.603 16.0645 28.4168C15.8833 28.2306 15.7399 28.0116 15.6342 27.76C15.5335 27.5033 15.4832 27.2264 15.4832 26.9295Z"
      fill="white"
    />
    <path
      d="M23.0662 25.0118L20.756 26.8087L23.1945 28.8547V28.9075H22.7642L20.4162 26.9068V28.9075H20.084V23.5472H20.4162V26.7181L22.6661 24.959H23.0662V25.0118Z"
      fill="white"
    />
    <path
      d="M27.4424 28.4772C27.236 28.6382 26.9995 28.7691 26.7327 28.8698C26.471 28.9654 26.174 29.0132 25.8418 29.0132C25.4694 29.0132 25.1498 28.9578 24.883 28.8471C24.6163 28.7364 24.3973 28.5879 24.2262 28.4017C24.0551 28.2104 23.9292 27.9915 23.8487 27.7449C23.7682 27.4932 23.7279 27.2264 23.7279 26.9446C23.7279 26.6577 23.7732 26.3884 23.8638 26.1368C23.9594 25.8851 24.0953 25.6636 24.2715 25.4724C24.4527 25.2811 24.6691 25.1301 24.9208 25.0194C25.1724 24.9087 25.4568 24.8533 25.7739 24.8533C26.0759 24.8533 26.3351 24.8986 26.5515 24.9892C26.768 25.0748 26.9466 25.193 27.0876 25.344C27.2335 25.495 27.3392 25.6712 27.4046 25.8725C27.4751 26.0688 27.5103 26.2777 27.5103 26.4991C27.5103 26.6048 27.5028 26.7105 27.4877 26.8162C27.4776 26.9169 27.4625 27.0025 27.4424 27.0729H24.0601C24.0702 27.2994 24.1104 27.5133 24.1809 27.7147C24.2564 27.916 24.3646 28.0921 24.5055 28.2431C24.6515 28.3891 24.8352 28.5049 25.0567 28.5904C25.2781 28.676 25.5399 28.7188 25.8418 28.7188C26.1539 28.7188 26.4257 28.671 26.6572 28.5753C26.8938 28.4747 27.1027 28.3564 27.2839 28.2205L27.4424 28.4772ZM27.1555 26.786C27.1656 26.7357 27.1731 26.6829 27.1782 26.6275C27.1832 26.5721 27.1857 26.5168 27.1857 26.4614C27.1857 26.2903 27.158 26.1267 27.1027 25.9707C27.0473 25.8096 26.9617 25.6687 26.846 25.5479C26.7352 25.4271 26.5893 25.3314 26.4081 25.261C26.2319 25.1855 26.0205 25.1477 25.7739 25.1477C25.5122 25.1477 25.2781 25.193 25.0718 25.2836C24.8654 25.3692 24.6893 25.4875 24.5433 25.6385C24.3973 25.7844 24.2816 25.9581 24.196 26.1594C24.1155 26.3557 24.0727 26.5646 24.0677 26.786H27.1555Z"
      fill="white"
    />
    <path
      d="M28.6161 24.959H28.9483V25.8499C29.0087 25.7089 29.0792 25.5806 29.1597 25.4648C29.2453 25.3491 29.3384 25.2484 29.4391 25.1628C29.5448 25.0773 29.6605 25.0118 29.7864 24.9665C29.9172 24.9162 30.0607 24.891 30.2167 24.891C30.408 24.891 30.5665 24.9212 30.6923 24.9816C30.8182 25.037 30.9364 25.1125 31.0472 25.2081L30.8886 25.4799C30.798 25.3994 30.6923 25.334 30.5715 25.2836C30.4558 25.2333 30.3249 25.2081 30.1789 25.2081C30.0229 25.2081 29.8795 25.2383 29.7486 25.2987C29.6228 25.3541 29.507 25.4321 29.4013 25.5328C29.3007 25.6334 29.2126 25.7517 29.1371 25.8876C29.0616 26.0185 28.9987 26.1594 28.9483 26.3104V28.9075H28.6161V24.959Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_3540_3"
        x="0.921899"
        y="1.23674"
        width="35.1562"
        height="37.5651"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.660377" />
        <feGaussianBlur stdDeviation="1.32075" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254902 0 0 0 0 0.25098 0 0 0 0 0.258824 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3540_3"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3540_3"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default PickupPinLocker;
