import { useState } from "react";
import { Modal } from "@dpdgroupuk/mydpd-ui";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Cross } from "~/components/Icon";
import { SELECT_NEW_PICKUP } from "~/constants/strings";
import { getFilteredPickupPoints } from "~/models/pickupPoint";
import PickupPointsMap from "../PickupPointsMap";

import Filters from "./Filters/Filters";
import ActivePickupPoint from "./ActivePickupPoint/ActivePickupPoint";
import PickupPointsList from "./PickupPointsList/PickupPointsList";
import styles from "./SelectPickupPointModal.module.scss";

const SelectPickupPointModal = ({
  deliveryAddress,
  open,
  onHide,
  pickupPoints,
  selectedPickupPoint,
  onPickupLocationChange,
}) => {
  const [activePickupPoint, setActivePickupPoint] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const filteredPickupPoints = getFilteredPickupPoints(
    pickupPoints,
    selectedType,
    selectedAmenities
  );

  return (
    <Modal show={open} centered size="sm" dialogClassName={styles.container}>
      <>
        <Modal.Header className="w-100 d-flex justify-content-between">
          <div className="w-100 d-flex justify-content-between">
            <span>{!activePickupPoint && SELECT_NEW_PICKUP}</span>
            <Cross
              cursor="pointer"
              fill="black"
              fill-opacity="0.56"
              onClick={
                activePickupPoint ? () => setActivePickupPoint(null) : onHide
              }
              className="align-self-end"
            />
          </div>
        </Modal.Header>

        {activePickupPoint && (
          <ActivePickupPoint
            pickupPoint={activePickupPoint}
            onSelectPickupPoint={() => {
              onHide();
              setActivePickupPoint(null);
              onPickupLocationChange(activePickupPoint);
            }}
          />
        )}
        <Modal.Body
          className={classNames(styles.body, activePickupPoint && "d-none")}
        >
          <div className={classNames(styles.mapContainer)}>
            <PickupPointsMap
              deliveryAddress={deliveryAddress}
              pickupPoints={filteredPickupPoints}
              onPickupPointClick={setActivePickupPoint}
              selectedPickupPoint={selectedPickupPoint}
              activePickupPoint={activePickupPoint}
            />
          </div>
          <div className={classNames(styles.listContainer)}>
            <Filters
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedAmenities={selectedAmenities}
              setSelectedAmenities={setSelectedAmenities}
            />
            <PickupPointsList
              pickupPoints={filteredPickupPoints}
              setActivePickupPoint={setActivePickupPoint}
            />
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

SelectPickupPointModal.propTypes = {
  deliveryAddress: PropTypes.object,
  open: PropTypes.bool,
  onHide: PropTypes.func,
  pickupPoints: PropTypes.array,
  selectedPickupPoint: PropTypes.object,
  onPickupLocationChange: PropTypes.func,
};

export default SelectPickupPointModal;
