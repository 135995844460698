import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { IMPORT_TEMPLATE_FORM, ImportsFields } from "~/constants/forms";
import * as S from "~/constants/strings";
import importTemplateFileSchema from "~/models/validators/importTemplateFileSchema";
import styles from "~/pages/Imports/components/FileOptions/FileOptions.module.scss";
import createValidator from "~/utils/joiReduxForm";
import { formatMessage } from "~/utils/string";

const FieldImportExportFile = ({
  handleSubmit,
  onClickImportTemplate,
  exportCsvLink,
  disableExport,
}) => (
  <Row>
    <Col xs={6}>
      <Field
        label={S.SELECT_FILE}
        component={FormControl.Input}
        type="file"
        accept=".csv"
        name={ImportsFields.TEMPLATE_FILE}
        onBlur={event => {
          event.preventDefault(); // used to avoid setting empty object to store when prompt window was opened
        }}
      />
    </Col>
    <Col xs={6}>
      <Button
        className={styles.templateBtn}
        onClick={handleSubmit(onClickImportTemplate)}
        variant="primary"
      >
        {formatMessage(S._$_TEMPLATE, S.IMPORT)}
      </Button>
      <Button
        className={styles.templateBtn}
        href={exportCsvLink}
        variant="secondary"
        disabled={disableExport}
      >
        {formatMessage(S._$_TEMPLATE, S.EXPORT)}
      </Button>
    </Col>
  </Row>
);

FieldImportExportFile.propTypes = {
  handleSubmit: PropTypes.func,
  onClickImportTemplate: PropTypes.func,
  exportCsvLink: PropTypes.string,
  disableExport: PropTypes.bool,
};

export default reduxForm({
  form: IMPORT_TEMPLATE_FORM,
  shouldError: () => true,
  forceUnregisterOnUnmount: true,
  validate: createValidator(importTemplateFileSchema),
})(FieldImportExportFile);
