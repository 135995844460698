const OpenWindowList = ({ openWindows }) => (
  <>
    {openWindows.map(({ day, openWindow }) => (
      <div className="d-flex justify-content-between">
        <span>{day}</span>
        <span>{openWindow}</span>
      </div>
    ))}
  </>
);

export default OpenWindowList;
